import React from "react"
import { MobileRule } from "../constants"
import clearly from "../assets/clearly.png"
import { compose, withProps } from "recompose"
import mapMarker from "../assets/map-marker.png"
import Link from "gatsby-link"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
/** @jsx jsx */
import { jsx } from "@emotion/core"

const modalStyles = {
  content: {
    top: "100px",
    border: "none",
    borderRadius: "0.5rem",
    maxWidth: "600px",
    margin: "auto",
  },
}

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB07HnxU-BhoQ2Q1p9zawSbsN0EbA0CfaQ&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultOptions={{
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#212121",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#2d333b",
            },
            {
              lightness: 80,
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#2d333b",
            },
            {
              lightness: 0,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 0,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 9,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 8,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 6,
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#2d333b",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#2d333b",
            },
          ],
        },
      ],
    }}
    defaultZoom={10}
    defaultCenter={{ lat: 59.334029, lng: 18.2601255 }}
  >
    <Marker
      position={{ lat: 59.334029, lng: 18.066258 }}
      defaultIcon={mapMarker}
    />
    <Marker
      position={{ lat: 59.312974, lng: 18.449512 }}
      defaultIcon={mapMarker}
    />
  </GoogleMap>
))
const Footer = () => {
  function openModal(){
    document.body.style.overflow = "hidden"
    setIsOpen(true);
  }
  function closeModal(){
    document.body.style.overflow = 'auto'
    setIsOpen(false);
  }
  const [modalIsOpen, setIsOpen] = React.useState(false)
  return (
    <StaticQuery
        query={graphql`
          query {
            cert1: file(relativePath: { eq: "certs/ski-cert.png" }) {
              childImageSharp {
                fixed(height: 50, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            cert2: file(relativePath: { eq: "certs/srf-cert.png" }) {
              childImageSharp {
                fixed(height: 40, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            cert3: file(relativePath: { eq: "certs/triple.png" }) {
              childImageSharp {
                fixed(height: 40, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            cert4: file(relativePath: { eq: "certs/bv-cert.png" }) {
              childImageSharp {
                fixed(height: 40, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        `}
    render={data => (
    <footer
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <section
        css={{
          [MobileRule]: {
            flexDirection: "column",
          },
          backgroundColor: "hsl(215, 14%, 23%)",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          css={{
            [MobileRule]: {
              width: "100%",
              textAlign: "center",
              flexDirection: "column",
              padding: "1rem 0rem",
            },
            textAlign: "left",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "50%",
            flexDirection: "row",
            padding: "1rem",
          }}
        >
        <div>
          <div
            css={{
              [MobileRule]: {
                fontSize: "1.5rem",
                color: "#ffffff",
                display: "block",
                margin: "1rem",
              },
              display: "none",
            }}
          >
            Can you see Clearly now?
          </div>
          <div
            css={{
              [MobileRule]: {
                display: "none",
              },
              color: "#ffffff",
              fontSize: "2.5rem",
              margin: "1rem",
            }}
          >
            Can you see <br/> Clearly now?
          </div>
          </div>

          <div
            css={{
              margin: "1rem 0rem",
            }}
          >
            <h3
              css={{
                [MobileRule]: {
                  fontSize: "1.25rem",
                },
                color: "#ffffff",
                fontSize: "1.75rem",
                margin: "0.25rem auto",
              }}
            >
              Här finns vi!
            </h3>
            <span
              css={{
                display: "block",
                color: "#ff857b",
                fontSize: "1.125rem",
              }}
            >
              Våra kontor
            </span>
            <p
              css={{
                display: "block",
                color: "#ffffff",
                fontSize: "1rem",
                margin: "1rem auto",
                lineHeight: "25px",
              }}
            >
              Clearly AB <br /> Skärgårdsvägen 158 <br /> 139 36 Värmdö
            </p>
            <p
              css={{
                display: "block",
                color: "#ffffff",
                fontSize: "1rem",
                margin: "1rem auto",
                lineHeight: "25px",
              }}
            >
              Mäster Samuelsgatan 36 <br /> 111 57 Stockholm
            </p>
          </div>
        </div>
        <div
          css={{
            [MobileRule]: {
              width: "100%",
            },
            width: "50%",
          }}
        >
          <MyMapComponent id="map" css={{ height: "400px" }}></MyMapComponent>
        </div>
      </section>
      <section
        css={{
          [MobileRule]: {
            padding: "1rem 2rem",
          },
          backgroundColor: "#2d333b",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          color: "rgb(69,79,92)",
          padding: "1rem 4rem",
        }}
      >
        <div>
          <a href="/">
            <img css={{ height: "45px" }} src={clearly} alt="Clearly" />
          </a>
        </div>
        <div css={{ display: "flex", flexDirection: "row" }}>
          <a
            href="https://www.facebook.com/Clearly-AB-466757043346360/"
            title="Facebook"
            css = {{
              display: "flex",
            }}
          >
            <svg
              css={{ [MobileRule]: {height: "26px"},height: "32px", margin: "0.25rem" }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook-square"
              className="svg-inline--fa fa-facebook-square fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                css={{
                  "&:hover": {
                    fill: "#fff",
                  },
                }}
                fill="rgb(69,79,92)"
                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/clearly-ab/"
            title="LinkedIn"
            css = {{
              display: "flex",
            }}
          >
            <svg
              css={{ [MobileRule]: {height: "26px"}, height: "32px", margin: "0.25rem" }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="linkedin"
              className="svg-inline--fa fa-linkedin fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                css={{
                  "&:hover": {
                    fill: "#fff",
                  },
                }}
                fill="rgb(69,79,92)"
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              ></path>
            </svg>
          </a>
        </div>
      </section>
      <section css={{[MobileRule]: {flexDirection: 'column'},display: 'flex', justifyContent: 'space-between', padding: '0rem 4rem 2rem 4rem', backgroundColor: "#2d333b", alignItems: 'center'}}>
        <div css={{[MobileRule]:{paddingBottom:'1.5rem'},display: 'flex', alignItems: 'center'}}>
            <a href="http://www.kvalitetsindex.se/branschundersokningar/redovisning/" target="_blank" css={{display: 'flex'}}><Img alt="SKI certificate" css={{"&:hover": {filter: 'grayscale(0%)', opacity: '1', transition: 'filter, opacity 0.2s ease'}, filter: 'grayscale(100%)', opacity: '0.5', marginRight: '1rem', transition: 'filter 0.2s ease',}} fixed={data.cert1.childImageSharp.fixed} /></a>
            <a href="https://www.srfkonsult.se/om-srf/" target="_blank" css={{display: 'flex'}}><Img alt="Srf certifikate" css={{"&:hover": {filter: 'grayscale(0%)', transition: 'filter, opacity 0.2s ease', opacity: '1',},  filter: 'grayscale(100%)', opacity: '0.5', transition: 'filter, opacity 0.2s ease',}} fixed={data.cert2.childImageSharp.fixed} /></a>
            <Img alt="Srf certifikate" css={{"&:hover": {filter: 'grayscale(0%)', transition: 'filter, opacity 0.2s ease', opacity: '1',},  filter: 'grayscale(100%)', opacity: '0.5', transition: 'filter, opacity 0.2s ease',}} fixed={data.cert3.childImageSharp.fixed} />
            <a href="https://www.branschvinnare.se/" target="_blank" css={{display: 'flex'}}><Img alt="Branschvinnare certifikate" css={{"&:hover": {filter: 'grayscale(0%)', transition: 'filter, opacity 0.2s ease', opacity: '1',},  filter: 'grayscale(100%)', opacity: '0.5', transition: 'filter, opacity 0.2s ease',}} fixed={data.cert4.childImageSharp.fixed} /></a>
        </div>
        <div css={{[MobileRule]:{alignItems: 'center'},display: 'flex', alignItems:'flex-end', flexDirection: 'column', color: 'rgb(69,79,92)', fontSize: "0.8rem"}}>
          <span>Copyright © 2021</span>
          <Link css={{[MobileRule]: {display: 'flex', flexDirection: 'column', marginLeft: '0'}, color: 'rgb(69,79,92)', cursor: 'pointer' }} to="/gdpr">
            GDPR / Integritetspolicy
          </Link>
        </div>
      </section>
    </footer>
    )}
  />
  )
}
export default Footer
